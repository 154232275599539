import {graphql, StaticQuery} from 'gatsby'
import React, {useState} from 'react'
import Layout from '../components/layout'
import get from 'lodash/get'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    allSanitySiteMenus {
      edges {
        node {
          footerMenu {
            link {
              ... on SanityExternalLink {
                _key
                _type
                linkDetails {
                  title
                  icon {
                    altText
                    asset {
                      path
                    }
                  }
                  browserTarget
                  linkDisplayType
                }
              }
              ... on SanityPageReference {
                _key
                _type
                linkDetails {
                  title
                  linkDisplayType
                  browserTarget
                  icon {
                    asset {
                      path
                    }
                    altText
                  }
                }
              }
            }
          }
          navMenu {
            link {
              ... on SanityExternalLink {
                _key
                _type
                linkDetails {
                  title
                  browserTarget
                  linkDisplayType
                  icon {
                    asset {
                      path
                    }
                    altText
                  }
                }
              }
              ... on SanityPageReference {
                _key
                _type
                linkDetails {
                  title
                  browserTarget
                  linkDisplayType
                  icon {
                    altText
                    asset {
                      path
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  function handleShowNav () {
    setShowNav(true)
  }
  function handleHideNav () {
    setShowNav(false)
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        const navMenuData = get(data, 'allSanitySiteMenus.edges[0].node.navMenu.link')
        const footerMenuData = get(data, 'allSanitySiteMenus.edges[0].node.footerMenu.link')
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          )
        }
        return (
          <Layout
            {...props}
            showNav={showNav}
            siteTitle={data.site.title}
            onHideNav={handleHideNav}
            onShowNav={handleShowNav}
            navMenu={navMenuData}
            footerMenu={footerMenuData}
          />
        )
      }}
    />
  )
}

export default LayoutContainer
